.projects {
    background-color: var(--verde);
    padding: 50px 0;
}

.card-projects {
    cursor: pointer;

    width: 360px;
    height: 250px;
    border-radius: 0 15px 15px 0;
    background-color: #1A3F46;
    margin: 10px;
    box-shadow: var(--box-shadow);
    position: relative;
    transition: var(--transition);
}

.group-text {
    margin: 20px;
    height: 80%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-projects::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    box-shadow: var(--box-shadow-azul);
    background-color: var(--azul);
}

.card-projects:hover {
    transform: translateY(-10px);
    transition: var(--transition);
}

.projects .pt {
    padding: 50px 50px 0 0;
}

.projects .subtitle {
    display: flex;
    padding-bottom: 5px;
    justify-content: space-between;
}
.subtitle i:hover{
    
  transform: scale(1.1);
 color: var(--azul);
}

.tec-color {
    list-style: none;
    display: flex;
    flex-wrap: wrap;

}

.projects .title {
    margin-bottom: 40px;
}

.projects .text {
    padding: 0;
}


.projects .tec-list ul {
    padding: 0;
}

@media (max-width:991px) {
    .projects {
        background-color: var(--verde);
        padding: 100px 10px 0 10px;
    }

    .projects .pt {
        padding: 50px 0;
    }

    .projects .title {
        flex-direction: column;
    }

    .projects .title::after {
        margin-top: 10px;
    }

    .card-projects {
        max-width: 300px;
        margin-bottom: 30px;
    }

    .body-projects {
        justify-content: center;

    }

    .tec-color {
        list-style: none;
        display: flex;
        flex-wrap: wrap;

    }

}