.contato {
    background-color: var(--verde);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-between;

}
.body-contato{

    justify-content: center;
}

footer.text{
    background-color: var(--verde);
    text-align: center;
    padding-bottom: 20px;
    padding-top: 100px;
}

.contato .title::after {
    display: none;
}


.contato .title {
    display: flex;
    justify-content: center;
}

.contato .text {
    
}