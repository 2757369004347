.work {
    background: linear-gradient(var(--bgk-claro), var(--verde));

}

.work .row {
    justify-content: center;
}


.work .title {
    justify-content: center;

}

.work .subtitle h2 {
    align-content: end;
}


.work-svg {
    max-width: 450px;
    transition: var(--transition);
}

.work-svg:hover {
    transform: scale(1.1);
    transition: var(--transition);
}






.card-work {
    display: flex;
    align-items: start;
    justify-content: start;

    flex-direction: column;

    padding: 30px 0;
}

.card-work:last-child {
    align-items: end;

}



.tec-list ul {
    list-style: none;
    display: flex;

}

.tec-list ul li {
    padding-left: 10px;

}

.left .subtitle,
.left .text,
.left ul {
    padding-left: 4rem;
}

.left .button {
    margin-left: 4rem;
}

.card-work.right {
    align-items: start;
}

.card-work.right.txt {
    align-items: end;
}

.card-work.txt {
    justify-content: center;
}


@media (max-width:991px) {
    .work-svg {
        max-width: inherit;
        transition: var(--transition);
    }
    .work {
        padding: 100px 10px 0 10px;
    }

    .work .title {
        flex-direction: column;
        text-align: center;
    }

    .work .title::after {
        margin-top: 20px;
    }
    .card-work{
        text-align: left;
    }
    .card-work.right.txt {
        align-items: start;

    }

    .text.right,
    .text.left {
        text-align: left;
        padding-left: 10px;
    }
    .experience-row{
        flex-direction: column-reverse;
    }

    .colunas.right {
        margin-top: 10px;
    
      }

    .work .subtitle,
    .work .tec-color {
        padding: 0 10px;
    }

    .tec-color li {
        padding-left: 0 !important;
        padding-right: 10px;
    }

    .work .button,
    .left .button {
        margin: 0 10px;
    }

    .work .row.center {
        flex-direction: column-reverse !important;
    }
}