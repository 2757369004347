.App {
    width: 100%;
    height: 100%;
}

.icons {
    width: 50px;
    position: fixed;
    top: 20%;
    left: 10px;
    right: auto;
    z-index: 10;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}



.icons ul {
    list-style: none;
}


.icons ul li {
    margin: 30px 0 30px 0;
    cursor: pointer;
}

.icons ul li:hover {
    transform: scale(1.1);
}

.icons ul::before {
    content: "";
    display: block;
    width: 1px;
    height: 90px;
    margin: 0px auto;
    background-color: var(--cinza-escuro);

}

.icons ul::after {
    content: "";
    display: block;
    width: 1px;
    height: 90px;
    margin: 0px auto;
    background-color: var(--cinza-escuro);

}






@media (max-width:991px) {
    .icons {
        display: none;
    }

}