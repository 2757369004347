.experience {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, var(--bgk-dark) 60%, var(--bgk-claro));
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.experience .text {
    padding: 0;
    margin: 15px;
}

.experience .title{
    justify-content: center;
}
.moon {
    position: absolute;
    top: 30%;
    left: 0;
}

.experience .container {
    padding-top: 50px;
}

.experience-row {
    display: flex;
    justify-content: center;
}

.title h2 {
    font-weight: bold;

}

 

.line-v {
    width: 1px;
    height: 100%;
    background: var(--cinza-escuro);

}

.colunas.line {
    margin: 0;
    padding: 0;
    width: 1px;
}

.colunas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.colunas.left {
    align-items: end !important;
    justify-content: end;
    text-align: right;
    margin-top: 200px;
    margin-right: 10px;
}

 

.colunas.right {
    align-items: start !important;
    justify-content: start;
    text-align: left;
    margin-top: 50px;
    margin-left: 10px;
}

.experience .left .subtitle::before {
    content: "";
    position: absolute;
    background-color: var(--bgk-dark);
    border: 5px solid #243947;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    right: -23px;
    z-index: 99;
}

.date {
    color: var(--cinza-escuro);
}

.experience .right .subtitle::before {
    content: "";
    position: absolute;
    background-color: var(--bgk-dark);
    border: 5px solid #243947;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    left: -23px;
    z-index: 99;
}


.experience .right .text p::before {
    content: "▹";
    position: absolute;
    left: 0px;
    color: var(--azul);
    margin-left: 10px;

}

.experience .left .text p::before {
    content: "▹";
    transform: scaleX(-1);
    position: absolute;
    right: 0px;
    margin-right: 10px;
    color: var(--azul);
}

@media (max-width:991px) {

    .experience {
        padding: 100px 10px 0 10px;
    }

    .colunas line {
        display: none;
    }

    .experience .subtitle::before {
        display: none
    }

    .colunas.left {
        align-items: start !important;
        justify-content: start;
        text-align: left;
        margin: 10px 0 0 10px;
    }

    .colunas.left .text,
    .colunas.left .subtitle {
        padding: 0;
    }

    .experience .left .text p::before {
        margin-left: 10px;
        transform: scaleX(1);
        left: 0;
    }

    .experience .title {
        flex-direction: column;
    }

    .experience .title::after {

        margin-top: 10px;

    }
}