* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: Anurati;
  src: url(./assets/fonts/Anurati-Regular.otf);
}

:root {
  --azul: #50B7C5;
  --cinza-claro: #CCD6F6;
  --cinza-escuro: #8892B0;
  --verde: #172225;
  --bgk-dark: #161717;
  --bgk-claro: #161A1A;
  --transition: all 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  --box-shadow: -1px 5px 10px 1px rgba(0, 0, 0, 0.288);
  --box-shadow-azul: 0 0 30px 1px var(--azul);
  --font-family: 'Poppins', sans-serif;
  --font-logo: "Anurati", sans-serif;
  --fs-p: 16px;
  --fs-xm: 20px;
  --fs-m: 25px;
  --fs-xg: 35px;
  --fs-g: 50px;
  --border-radius: 5px;
}

html {
  width: 100%;
  height: 100%;
  scrollbar-width: thin;
  scrollbar-color: var(--azul) var(--bgk-dark);
}


#root,
main {
  width: 100%;
  height: 100%;
}


body {

  font-family: var(--font-family);
  width: 100%;
  background-color: var(--bgk-dark);
  width: 100%;
  height: auto;

}

.desfocus {
  overflow: hidden;

}

.button {
  color: var(--azul) !important;
  background-color: transparent;
  border: 1px solid var(--azul);
  border-radius: var(--border-radius);
  padding: 0.75rem 1rem;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  margin-left: 15px;
}

.button:hover {
  background-color: var(--verde);
}

.tec-color {
  color: var(--azul);
}

.pt {
  padding: 150px 0 0;
}

.subtitle h2,
.subtitle h3,
.subtitle h4,
.subtitle h5,
.subtitle i {
  font-size: var(--fs-xm);
  font-weight: bold;
  color: var(--cinza-claro);

}

@media (max-width:991px) {
  .pt {
    padding: 100px 0 0;
  }
}