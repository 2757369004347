 .Apr h1 {
     font-size: var(--fs-p);
     color: var(--azul);
 }

 .begin {
     padding-top: 100px;
 }

 .name h2 {
     font-size: var(--fs-g);
     color: var(--cinza-claro);
     font-weight: bold;
 }

 .adjective h3 {
     font-size: var(--fs-g);
     color: var(--cinza-escuro);
     font-weight: bold;
 }

 .apresentation p {
     font-size: var(--fs-p);
     color: var(--cinza-escuro);
 }

 .code {
     width: 90%;
     height: 130px;
     border-radius: var(--border-radius);
     background-color: #1F2526;
     box-shadow: var(--box-shadow);


 }

 .code p {
     padding: 10px 0 0 20px;
     font-size: var(--fs-p);
     color: #757A7E;
 }




 .input-begin {
     display: flex;
     align-items: start;
     height: 50%;

     flex-direction: row;
 }


 .input-begin.correct input {
     color: var(--azul);
 }


 .input-begin.correct input:focus {
     color: var(--azul);
 }



 .input-begin p {
     padding-top: 0;
     color: #228FC8;
     margin: 0 10px 0 0;
 }

 .input-begin input {
     height: 25px;
     width: 50%;

     background-color: transparent;
     border: none;
     color: #757a7e;
 }

 .input-begin input:focus {
     outline: none;
     color: #DB960C;
 }

 .entrar {
     font-size: 20px;
     color: var(--cinza-escuro);
     
     opacity: 0;
 }

 .entrar.correct {
     color: var(--azul);
     cursor: pointer;
     opacity: 1;
     animation: popup 0.5s;
     transition: var(--transition);
 }

 @keyframes popup {
    0%{
        transform: scale(0);
      }
      60%{
        transform: scale(1.4);
      }
      90%{
        transform: scale(1);
      }
 }


 hr {
     padding: 0 !important;
     color: #757A7E;
 }

 .digit {
     background-color: #DB960C;
     height: 25.1px;
     width: 1px;
 }

 @media (max-width:991px) {
     .begin {
         padding: 100px 10px 0 10px
     }

     .adjective h3,
     .name h2 {
         font-size: var(--fs-xg);
     }

     .code {
         margin-bottom: 20px;
     }

     .code input {
         width: 30%;
     }

     #body-principal.active {
         filter: blur(5px);
         transition: var(--transition);
     }
 }