 .title {
     display: flex;
     flex-direction: row;
     align-items: center;
     color: var(--cinza-claro);
     font-size: var(--fs-m);

     padding: 25px 10px 0 10px;
 }

 .title h3 {
     font-weight: bold;
 }

 .title::after {
     content: "";
     display: block;
     position: relative;
     top: -5px;
     width: 300px;
     height: 1px;
     margin-left: 20px;
     background-color: #4C515D;
 }

 .text {
     padding: 20px 10px;
     color: var(--cinza-escuro);
     font-size: var(--fs-p);

 }

 .detalhes {
     width: 200px;
     height: 115px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     text-align: center;
     position: relative;
     background: linear-gradient(to right, #355F62, #305C58);
     border-radius: 15px;
     box-shadow: var(--box-shadow);
 }

 #svg-tecMais {
     max-width: 200px;
 }

 .detalhes h2 {
     font-size: var(--fs-xp);
     color: #fff;
     font-weight: 300;
     padding-bottom: 15px;

 }

 .detalhes .button {
     border: none;
     background: linear-gradient(to right, #1BE2B6, #1DC8CE);
     color: #175D57 !important;
     text-transform: uppercase;
 }

 .detalhes::after {
     content: "";
     position: absolute;
     border-radius: 15px;
     width: 100%;
     height: 100%;
     top: -14px;
     left: 16px;
     z-index: -1;
     top: -14px;
     left: 16px;
     background-color: #1D2F33;
 }

 .card-about {
     display: flex;
     justify-content: center;
 }

 .body-card-about {
     padding: 3rem 3rem 0 3rem;
 }

 @media (max-width:991px) {


     .body-card-about {
         padding: 1rem 3rem 0 3rem;
         flex-direction: column-reverse !important;
     }

     .about .row {
         display: flex;
         flex-direction: column-reverse;
         justify-items: center;
         align-items: center;
     }

     .title::after {
         width: 40%;
     }

     .card-about {
         padding: 10px;
     }



 }